
import { NgTenants } from './index';

export class AllContractYears {
   public contractYearId: number = 0;
   public contractYear: string = null;
   public startDate: Date = new Date(0);
   public endDate: Date = new Date(0);
   public tenantId: number = 0;
   public tenant: NgTenants = null;
}